@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

.project-category-buttons {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
}

body, button {
    font-family: 'Nunito', sans-serif;
}

.project-category-buttons button {
    padding: 10px 20px;
    margin-right: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease, color 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.project-category-buttons button:hover {
    background-color: #dedede;
    color: #333;
    transform: scale(1.05);
}

.project-category-buttons button:active {
    transform: scale(0.95);
}

.project-category-buttons button.active {
    background-color: #007bff;
    color: white;
    border-color: #0056b3;
}

.arrow-button {
    border-radius: 50%;
    background-color: #ffffff;
    color: #007bff;
    width: 50px;
    height: 50px;
    padding: 0;
    border: 2px solid #007bff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.arrow-button:hover {
    background-color: #007bff;
    color: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

.projects-section {
    padding: 20px;
}

.project-row {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.project {
    flex-grow: 1;
    margin: 10px;
    width: calc(50% - 20px);
    box-sizing: border-box;
}
.project h3 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
}

.project-image-container {
    overflow: hidden;
    position: relative;
    height: 300px; /* Set a fixed height for the container */
}

.project-images {
    display: flex;
    transition: transform 0.5s ease-out;
    width: 100%;
    height: 100%; /* Make it full height of the container */
}

.project-image {
    min-width: 100%;
    height: 100%; /* Make it full height of the container */
    object-fit: cover; /* This will cover the area without stretching the image */
    object-position: center; /* This centers the image within its container */
    transition: opacity 0.5s ease-in-out;
}

@media screen and (max-width: 768px) {
    .arrow-button {
        display: flex;
        width: 40px;
        height: 40px;
    }
}