@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

/* Reset some default styles */
body, h1, h2, h3, p, a {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #000000;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* Header styles */
.App-header {
  background-color: rgba(9, 9, 52, 0.897);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: white;
}

/* Style for the content container */
.App-content {
  margin-left: 20%; /* Adjust content margin to leave space for the navbar */
  padding: 10px;
  width: 80%; /* Take up 80% of the screen width */
  float: right; /* Float the content container to the right */
}

/* About section styles */
.about-section {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 2000px;
}

.about-section h2 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #333;
}

.about-section p {
  font-size: 20px;
  line-height: 1.6;
  color: #000000;
}

/* Projects section styles */
.projects-section {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  max-width: 2000px;
}

.projects-section h2 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #333;
}

.projects-section h4 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.project-group {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  overflow-x: auto;
  gap: 20px;
}

.project {
  position: relative;
  width: calc(50% - 10px);
  border: 1px solid #ddd;
  padding: 15px;
  margin: 15px 0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.project .project-image-container {
  max-width: 100%;
  height: 450px; /* Set a fixed height for all images */
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: contain; /* Use 'auto' for scrollbars when needed */
}

.project img {
  max-width: 100%;
  height: 100%;
  max-height: 450px;
  object-fit: scale-down;
  border-radius: 20px;
}

.project p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-top: 10px;
}

/* Contact section styles */
.contact-section {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 2000px;
}

.contact-section h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.contact-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
}

.contact-section a {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

/* Section with fade-in animation */
.section-fade {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;
}

/* Slideshow arrow button styles */
.arrow-button {
  background: rgba(216, 216, 216, 0.5);
  border: none;
  font-size: 24px;
  color: rgb(213, 19, 19);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
}

.left-arrow {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.right-arrow {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-picture {
  width: 200px; /* Or the size you prefer */
  height: 200px; /* Should be the same as width for a perfect circle */
  border-radius: 50%; /* This creates the circular shape */
  border: 2.5px solid white; /* Optional: adds a white border around the picture */
  object-fit: cover; /* This will cover the area without stretching the picture */
  margin-bottom: 20px; /* Adds some space below the picture */
}

.connect-container {
  text-align: center; /* Center the links container */
  padding: 10px 0; /* Add some vertical padding */
}

.connect-container a {
  margin: 0 10px; /* Add horizontal margin between links */
  text-decoration: none; /* Remove the underline from links */
  color: #007bff; /* Blue color for links */
  transition: color 0.3s ease; /* Smooth color transition for hover effect */
}

.connect-container a:hover {
  color: #0056b3; /* Darker shade of blue on hover */
}